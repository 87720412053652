<template>
  <div class="the-footer-details">
    <div class="begin w-full flex justify-center items-center px-24 py-4">
      <div class="flex flex-col justify-center">
        <div class="contenu flex items-center">
          <div class="title text-black mr-3 text-xl">{{ $t('estimateInsurancePrime') }}</div>
          <div class="bouton ml-3">
            <button-base
              @click.native="goToWebsite"
              :label="$t('getQuoteButton')"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonBase from './add/button-base'
export default {
  name: 'the-footer-details',
  components: { ButtonBase },
  methods: {
    goToWebsite () {
      switch (this.$route.name.toLowerCase()) {
        case 'auto' :
          window.open('https://app.aab.bj/accueil?product=automobile')
          break
        case 'moto':
          window.open('https://app.aab.bj/accueil?product=moto')
          break
        case 'habitation' :
          window.open('https://app.aab.bj/accueil?product=mrh')
          break
        case 'voyage':
          window.open('https://app.aab.bj/accueil?product=voyage')
          break
        default:
          window.open('https://app.aab.bj/accueil?product=auto')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "src/assets/style/sass/variables";
  .the-footer-details {
    box-shadow: -5px 0px 18px rgba(0, 0, 0, 0.15);
  }
  .bouton::v-deep {
    .button-base {
      .page-button-real {
        h4 {
          font-size: 1.25rem;
        }
      }
    }
  }
  @media only screen and (max-width: 1024px) {
    .begin {
      padding-right: 1rem;
      padding-left: 1rem;
    }
  }
</style>
