<template>
  <div class="details overflow-hidden">
    <div class="header fixed z-50 top-0 w-full bg-white">
      <the-header/>
    </div>
    <div class="body h-screen pt-32 pb-12 px-24">
      <router-view class="viewer m-auto"/>
    </div>
    <div class="footer w-full bg-white bottom-0">
      <the-footer-details/>
    </div>
  </div>
</template>

<script>
import TheHeader from '../../components/the-header'
import TheFooterDetails from '../../components/helper/the-footer-details'
export default {
  name: 'index',
  components: { TheFooterDetails, TheHeader }
}
</script>

<style lang="scss" scoped>
  @import "src/assets/style/sass/variables";
  .footer {
    position: fixed;
    bottom: 0;
  }
  .body {
    height: 90vh;
  }
  @media only screen and (max-width: 600px) {
    .body {
      height: auto;
      padding-bottom: 12rem;
    }
  }
  @media only screen and (min-width: 601px) and (max-width: 768px) {
    .body {
      height: auto;
      padding-bottom: 12rem;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    .body {
      height: auto;
      padding-bottom: 12rem;
    }
  }
  @media only screen and (max-width: 1024px) {
    .body {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }
</style>
